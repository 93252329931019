import { ref } from "vue";
import { useBuilder } from "@/views/builder/stores/Builder";

export function useTest() {
  const store = useBuilder();
  const builderData = ref();
  const builderLoading = ref(true)

  const initBuilder = async () => {
    store.$reset();
    await store.fetchComponents();
    builderData.value = store.getComponents;
    builderLoading.value = false;
  }

  return { 
    initBuilder,
    builderData,
    builderLoading,
  }
}